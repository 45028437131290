<script lang="ts" setup>

</script>

<template>
  <div>
    <slot />
  </div>
</template>

<style lang="scss" scoped>

</style>
